import { graphql, PageProps } from 'gatsby'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../../components/Layout'
import DocSidebar, { DocSidebarMenu } from '../../components/sidebar/DocSidebar'

interface Props extends PageProps<{ markdownRemark: any; allMarkdownRemark: any }> {}

function processMenus(nodes: any[]): DocSidebarMenu[] {
    const node_map = new Map<string, DocSidebarMenu>()
    nodes.forEach(node => {
        const sectionId = node.frontmatter.sectionId
        const section = node.frontmatter.section
        const id = node.frontmatter.id
        const title = node.frontmatter.title
        const herf = node.fields.permalink
        if (!node_map.has(sectionId)) {
            node_map.set(sectionId, { items: [], title: section, id: sectionId })
        }
        node_map.get(sectionId)?.items.push({ title, herf, id })
    })
    return [...node_map.values()].sort((a, b) => a.id - b.id)
}

const DocTemplate: React.FunctionComponent<Props> = props => {
    const nodes = props.data.allMarkdownRemark.nodes
    const location = props.location

    return (
        <Layout location={props.location} className="bg-light navbar-light">
            <Helmet>
                <title>欢迎使用CodeIN</title>
            </Helmet>
            <div className="doc-template__container">
                <DocSidebar menus={processMenus(nodes)} location={location}></DocSidebar>
                <div className="doc-template__body">
                    <header className="mt-6">
                        <h2 className="font-weight-bold">欢迎使用CodeIN</h2>
                    </header>
                </div>
            </div>
        </Layout>
    )
}

export default DocTemplate

export const pageQuery = graphql`
    query {
        allMarkdownRemark(filter: { fields: { permalink: { glob: "/docs/*" } } }) {
            nodes {
                frontmatter {
                    section
                    sectionId
                    id
                    title
                }
                fields {
                    permalink
                }
            }
        }
    }
`
